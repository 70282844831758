@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap");

:root {
    --main-red: #ed2e38;
    --blue: #8ed1fc;
    --pale-pink: #f9c2cc;
    --grey: #a9b8c3;
    --font: "Roboto", sans-serif;

    /* for book */
    /* colors */
    --body-bg: #fafafa;
    --page-bg: #f5f5f5;
    --dark-text: #2a2935;

    /* spacing */
    /* this is what defines the global scale */
    --baseline: 10px;

    /* fonts */
    --book-title: "Kalam", sans-serif;
    --base-size: var(--baseline) * 1.8;
}

html {
    scroll-behavior: smooth;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
body {
    max-width: 100vw;
    /* max-height: 100vh; */
}


.navigation {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    /* position: fixed; */
    padding: 0.5rem 0rem;
    z-index: 1;
    /* background-color: var(--blue); */

}

.navigation-menu,
.navigation-menu-dark {
    margin-left: auto;
}

.navigation-menu ul,
.navigation-menu-dark ul {
    display: flex;
    padding: 0;
}
.navigation-menu li {
    /* // removes default disc bullet for li tags and applies margin to left & right side */
    list-style-type: none;
    margin: 0 1rem;
    color: var(--main-red);
    font-family: var(--font);
    font-size: calc(var(--baseline) * 2.8);
}
.navigation-menu li a {
    /* // increases the surface area of the anchor tag to span more than just the anchor text */
    text-decoration: none;
    display: block;
    width: 100%;
    color: var(--main-red);
}

.navigation-menu li a:hover {
    color: #f9c2cc;
}

.navigation-menu-dark li {
    list-style-type: none;
    margin: 0 1rem;
    color: var(--blue);
    font-family: var(--font);
    font-size: calc(var(--baseline) * 2.8);
}

.navigation-menu-dark li a {
    /* // increases the surface area of the anchor tag to span more than just the anchor text */
    text-decoration: none;
    display: block;
    width: 100%;
    color: var(--blue);
}

.navigation-menu li a:hover {
    color: #f9c2cc;
}

.hamburger {
    /* // removes default border on button element */
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: var(--main-red);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    /* // positions the icon to the right and center aligns it vertically */
    position: fixed;
 /* top: 5vh; */
    right: 5vh; 
    /* transform: translateY(-50%); */
    display: none;
}

.hamburger-dark {
    /* // removes default border on button element */
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: var(--blue);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    /* // positions the icon to the right and center aligns it vertically */
    position: fixed;
    top: 5vh;
    right: 25px;
    transform: translateY(-50%);
    display: none;
}

/* .hamburger:hover {
    background-color: var(--main-red);
  } */

/* Particles  */
.particles-js canvas {
    display: block;
    /* vertical-align: bottom; */
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -webkit-transition: opacity 0.8s ease, -webkit-transform 1.4s ease;
    transition: opacity 0.8s ease, transform 1.4s ease;
}

.particles-js {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -10;
    top: 0;
    left: 0;
}

.App {
    display: flex;
    flex-direction: column;
    /* border: solid; */
    /* border-color: #ed2e38; */
    /* width: 100vw; */

}

.Home {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: var(--main-red);
    font-family: var(--font);
    height: 100vh;

}

.Home-Dark {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: var(--blue);
    font-family: var(--font);
    height: 100vh;
}

.About {
    display: flex;
    width: 100%;
    justify-content: center;
    color: var(--main-red);
    font-family: var(--font);
    height: 100vh;

}

.About-Dark {
    display: flex;
    width: 100%;
    justify-content: center;
    color: var(--blue);
    font-family: var(--font);
    height: 100vh;

}


.left-right {
    display: flex;
    width: 95vw;
    height: 70%;
    background-color: rgba(249, 194, 204, 0.86);
    border-radius: 8px;
}
.left-right-dark {
  display: flex;
  width: 95vw;
  height: 70%;
  background-color: rgba(62, 98, 89, 0.86);
  border-radius: 8px;
}

.about-left {
    width: 50%;
    display: flex;
/* background-color: aqua; */
    justify-content: center;
    padding: 2vh;
    /* opacity: 1; */
}

.about-right {
  width: 50%;
    display: flex;
    flex-direction: column;
    /* background-color: aqua; */
    justify-content: center;
align-items: center;
    font-size: 2.5vh;
    padding: 5vh;
    text-align: center;
}



.photo {
    /* height: calc(var(--baseline) * 50); */
    height: 90%;
    /* width: 37vw; */
    /* border-radius: 30px; */
    align-self: center;
    /* opacity: 1; */
    border: 1.5px solid var(--main-red);
    box-shadow: 8px 10px var(--main-red);
    border-radius: 8px;
}

.photo-dark {
  /* height: calc(var(--baseline) * 50); */
  height: 90%;
  /* width: 37vw; */
  /* border-radius: 30px; */
  align-self: center;
  /* opacity: 1; */
  border: 1.5px solid var(--blue);
  box-shadow: 8px 10px var(--blue);
  border-radius: 8px;
}

.Projects {
    flex-direction: column;
    /* height: 100vh; */
    align-items: center;
    text-align: center;
    font-family: var(--font);
  }
  
  .Projects-Dark {
    flex-direction: column;
    /* height: 100vh; */
    align-items: center;
    text-align: center;
    font-family: var(--font);
  }
  
  .projects-title {
    
    color: var(--main-red);
    font-size: 2.5vh;
  }
  
  .projects-title-dark {
  color: var(--blue);
  font-size: 2.5vh;
}

#projects {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    display: flex;
    flex-direction: row;
}

h1 {
    text-align: center;
    font-size: 12vh;
    font-weight: 300;
    font-family: var(--font);
}

p {
    font-size: 5vh;
    text-align: center;
    font-weight: 300;
}

.icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
}

.book-area {
    width: 90vw;
    justify-content: space-evenly;
    /* background-color: #ED2E38;  */
    perspective: 1200px;
    /* background: #ccc; */
}

.icon {
    width: calc(var(--baseline) * 5);
    height: calc(var(--baseline) * 5);
    padding-right: calc(var(--baseline) * 0.3);
}

.flower {
    width: calc(var(--baseline) * 3);
    height: calc(var(--baseline) * 3);
}

.about-flower {
  width: calc(var(--baseline) * 2);
  height: calc(var(--baseline) * 2);
  margin: 1vh;
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.button-56 {
    margin: 1vh;
    align-items: center;
    background-color: var(--pale-pink);
    border: 2px solid var(--main-red);
    border-radius: 8px;
    box-sizing: border-box;
    color: var(--main-red);
    cursor: pointer;
    display: flex;
    font-family: Inter, sans-serif;
    font-size: 16px;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    max-width: 100%;
    padding: 0 25px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-56-dark {
    margin: 1vh;
    align-items: center;
    background-color: #3e6259;
    border: 2px solid var(--blue);
    border-radius: 8px;
    box-sizing: border-box;
    color: var(--blue);
    cursor: pointer;
    display: flex;
    font-family: Inter, sans-serif;
    font-size: 16px;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    max-width: 100%;
    padding: 0 25px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-56:after {
    background-color: var(--main-red);
    border-radius: 8px;
    content: "";
    display: block;
    height: 48px;
    left: 0;
    width: 100%;
    position: absolute;
    top: -2px;
    transform: translate(8px, 8px);
    transition: transform 0.2s ease-out;
    z-index: -1;
}

.button-56-dark:after {
    background-color: var(--blue);
    border-radius: 8px;
    content: "";
    display: block;
    height: 48px;
    left: 0;
    width: 100%;
    position: absolute;
    top: -2px;
    transform: translate(8px, 8px);
    transition: transform 0.2s ease-out;
    z-index: -1;
}

.button-56:hover:after {
    transform: translate(0, 0);
}

.button-56:active {
    background-color: var(--pale-pink);
    outline: 0;
}

.button-56:hover {
    outline: 0;
}

/* DARK MODE BUTTON  */

.toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    margin-left: 5vw;
    transition: background 0.2s linear;

}
.toggle.dark {
    background-color: #292c35;
    
}
.checkbox {
    opacity: 0;
    position: absolute;
}

.toggle-label {
    width: 50px;
    height: 26px;
    background-color: grey;
    display: flex;
    border-radius: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    transform: scale(1.5);
}

.ball {
    width: 20px;
    height: 20px;
    background-color: white;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    transition: transform 0.2s linear;
}

/*  target the elemenent after the label*/
.checkbox:checked + .toggle-label .ball {
    transform: translateX(24px);
}

.fa-moon {
    color: pink;
}

.fa-sun {
    color: #ffe6a7;
}

@media (max-width: 900px) {

  :root {
    --baseline: 8px;
}

    .left-right, .left-right-dark {
        flex-direction: column;
        height: auto;
    }


    .about-left {
        width: 100%;
        justify-content: center;
    }

    .about-right {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
       
        /* padding-left: 3vw; */

        /* font-size: calc(var(--baseline) * 2); */
        padding-left: 10vh;
        padding-right: 10vh;
        /* overflow: scroll; */
    }
    .About,
    .About-Dark {
        height: auto;
    }
    .about-flower {
        width: calc(var(--baseline) * 3);
        height: calc(var(--baseline) * 3);
      }
    .photo, .photo-dark {
       width: 70vw;
        /* height: auto; */
        align-self: center;
        margin-top: 5vh;
    }
}

@media (min-width: 768px) {
    .button-56 {
        padding: 0 40px;
    }
}

@media screen and (max-width: 768px) {
    .hamburger,
    .hamburger-dark {
        display: block;
        z-index: 1;
    }
    .navigation-menu ul,
    .navigation-menu-dark ul {
        display: none;
    }
    .navigation-menu ul {
        /* // navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar) */
        position: fixed;
        top: 0;
        left: 0;
        /* // stacks the li tags vertically  */
        flex-direction: column;
        /* // makes menu span full height and width */
        width: 100%;
        background-color: var(--pale-pink);
    }

    .navigation-menu-dark ul {
        /* // navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar) */
        position: fixed;
        top: 0;
        left: 0;
        /* // stacks the li tags vertically  */
        flex-direction: column;
        /* // makes menu span full height and width */
        width: 100%;
        background-color: #3e6259;
    }

    .navigation-menu li,
    .navigation-menu-dark li {
        /* // centers link text and strips off margin */
        text-align: center;
        margin: 0;
        font-size: calc(var(--baseline) * 4);
    }
    .navigation-menu li a {
        color: var(--main-red);
        /* // increases the surface area of the anchor tag to span the full width of the menu */
        width: 100%;
        padding: 1.5rem 0;
    }
    .navigation-menu-dark li a {
        color: var(--blue);
        /* // increases the surface area of the anchor tag to span the full width of the menu */
        width: 100%;
        padding: 1.5rem 0;
    }
    .navigation-menu li:hover,
    .navigation-menu-dark li:hover {
        background-color: #eee;
    }
    .navigation-menu ul,
    .navigation-menu-dark ul {
        /* previous styles */
        display: none;
    }
    .navigation-menu.expanded ul,
    .navigation-menu-dark.expanded ul {
        display: block;
    }
}

/* When the browser is less than 600px */

@media screen and (max-width: 600px) {
    h1 {
        font-size: calc(var(--baseline) * 10);
    }

    p {
        font-size: 3vh;
    }

    .title {
        display: flex;
        flex-direction: column;
    }

    .book-container {
        display: flex;
        flex-direction: column;
    }

    .book-area {
        padding: 10px;
        /* background-color: #f5f5f5; */
        width: 80vw;
    }
}
@media screen and (max-width: 600px) {
    :root {
        --baseline: 6px;
    }
}
@media screen and (max-width: 450px) {
  .about-right {

    padding-left: 5vh;
    padding-right: 5vh;
}

}
@media screen and (max-width: 400px) {
    :root {
        --baseline: 5.5px;
    }

    
}
